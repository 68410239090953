import axios from 'axios'
import React, { Component } from 'react'
import Spinner from '../Spinner/Spinner'

export class DownloadMlwbd extends Component {
    state = {
        data:"",
        loading:true
    }
    componentDidMount(){
        if(this.state.loading){
            axios.get('?downloadid='+this.props.videoId)
            .then((response)=>{
     this.setState({
        data:response.data,
        loading:false
     })
            })
        }
      
    }
  render() {
    return (
      <>
      {this.state.loading?<Spinner/>: <div className='text-light'  dangerouslySetInnerHTML={{__html: this.state.data}}/>}
           
       
      </>
    )
  }
}

export default DownloadMlwbd