import React, { Component } from 'react'
import MovieCard from '../../../Component/MovieCard/MovieCard';
import Layout from '../Layout/Layout';

export class RecentPlaying extends Component {


    reverseObj = (obj) => {
    let newObj = {}
  
    Object.keys(obj)
      .sort()
      .reverse()
      .forEach((key) => {
        
        newObj[key] = obj[key]
      })
  
    
    return newObj  
  }

  reverseObjectIntoArray=(obj)=>{

    var array = [];
  
    for (var i in obj) {
      array.push(obj[i]);
    }
  
    array.reverse();
  
    return array;
  
  }
  
  render() {
    var renderMovie;
 
    if(localStorage.getItem('recent')){
     var recent = JSON.parse(localStorage.getItem('recent'));
      var recent = this.reverseObjectIntoArray(recent);
  renderMovie = Object.keys(recent).map((i)=>{
var url = i;
 
   var title = recent[i].title;
   var videoimage = recent[i].videoimage;
   var type = recent[i].type;
   if ('url' in recent[i]){
    var url = recent[i].url;
   }else{
    localStorage.removeItem('recent')
   }

   return <MovieCard poster ={videoimage}
   url={"/"+type+"/watch?url="+url}
   title = {title}
   type = {type}
   />
  })


    }
    return (
      <>
        <Layout>
     <div class="catalog" >
  
		<div class="container">
		 <div class="row">

     {/* <MovieCard poster ="https://www.themoviedb.org/t/p/w600_and_h900_bestv2/8ghNCfFbCJjcNSz2K5jOC3eO6ZD.jpg"
     url=""
     title = "Rat Race 2001 WEBDL-1080p.mkv"
     type = "movie"
     /> */}
{renderMovie}
 
		 </div>
		</div>
	</div>
  </Layout>
      </>
    )
  }
}

export default RecentPlaying