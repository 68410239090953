import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './MovieCard.css';
 
export class MovieCard extends Component {
    render() {
        return (
             <>
             <div class="col-6 col-sm-4 col-lg-3 col-xl-2">
				<div class="card">
					<div class="card__cover  ">
            <div className="card-type-title">
              {this.props.type}
            </div>
						<img src={this.props.poster}  alt=""/>
						<Link to={this.props.url} class="card__play">
							<i class="icon ion-ios-play"></i>
						</Link>
					</div>
					<div class="card__content">
						<h3 class="card__title"><Link to={this.props.url} dangerouslySetInnerHTML={{__html: this.props.title}}>
						 
							</Link></h3>
						<span class="card__category">
						
						{/* <a href="#">{this.props.type}</a> */}
						 	</span>
						{/* <span class="card__rate"><i class="icon ion-ios-star"></i>6.4</span> */}
					</div>
				</div>
			</div>
             </>
          
        )
    }
}

export default MovieCard