import React, { Component } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Spinner from '../../Spinner/Spinner';
export class JwPlayer extends Component {
  state = {
    startTime:0,
    loadvideo:true
  }
    componentDidMount(){
      
    this.startVideo(this.props.source);
    this.setState({loadvideo:true})
 
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.videoId !== prevProps.videoId) {
    
  //     window.jwplayer().stop()
  //     this.startVideo(this.props.url , this.props.subtitles);
    
  //   }
  // }

    startVideo( source  ){
      const jwplayer = window.jwplayer;
     
      
    



       var playerInstance = new jwplayer("EasyVideo");
      playerInstance.setup({
        autostart: true,
  width: "100%",
  height: "260",
  aspectratio: "16:9",
  sources:  source,
  events:{
      onComplete: function() {
          alert("Done");
      }
  }
  ,
  // ,
  // responsive: true,
  // width: "100%",
  // aspectratio: "16:9"
//  autostart: true
      });

      // let totalTimeWatched = 0;
      // let previousPosition = 0;
      
        let totalTimeWatched = 0;
        let previousPosition = 0;
        if(window.jwplayer()){
          var pos = 0;
          if(localStorage.getItem(this.props.videoId)){
            pos = parseFloat(localStorage.getItem(this.props.videoId));
          } 
            setTimeout(()=>{
              if(pos!=0){
  window.jwplayer().seek(pos);
              }
            
                jwplayer().on('time', (e) => {
                     const { position } = e;
                  totalTimeWatched += (position - previousPosition);
                  previousPosition = position;
                    localStorage.setItem(this.props.videoId, position)
                    
              });
               
              
            },1500)
        
          }
     

      




    }
  render() {
    /*
    
    jwplayer().getPosition()
1302.353418
jwplayer("").getState();
'paused'
    */
    return (
      <>
       <div class="vidContainer">
      <div id="EasyVideo"><Spinner/></div>
       
    </div>
      
      </>
    )
  }
}

export default (props) => (
  <JwPlayer
      {...props}
      params={useParams()}
  />
);