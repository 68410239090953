import React, { Component } from 'react'
import Header from '../../../Component/Hoichoi/Header/Header'
export class Layout extends Component {
  searchEngineHandler = (e)=>{
 
    this.props.searchHandlerFunction(e)
   }
  render() {
    return (
      <>
      <Header searchEngineHandler={this.searchEngineHandler}/>
      {this.props.children}
      
      </>
    )
  }
}

export default Layout