import React, { Component } from 'react'
import Header from '../../../Component/Hoichoi/Header/Header'
import MovieCard from '../../../Component/MovieCard/MovieCard'
import Layout from '../Layout/Layout'
import HomepageSlider from '../../../Component/Hoichoi/HomepageSlider/HomepageSlider'
import axios from 'axios'
import RecentPlaying from '../RecentPlaying/RecentPlaying'
import Spinner from '../../../Component/Spinner/Spinner'
export class HomePage extends Component {
state = {
  reacentAdd:"",
  data:"",
  loading:true,
  loadingrecent:true
} 
  componentDidMount(){
    document.title = "Download Movie And Series Free"
    axios.get("?homepagedata")
    .then((response)=>{
      var data = response.data;
   this.setState({data: data , loading:false})
    })
 
  }

  searchEngineHandler = (e)=>{
 
	}
  render() {
 

 var data = this.state.data;
 var renderData;
if(!this.state.loading){
 

   renderData = Object.keys(data).map((i)=>{
  console.log(data[i])
  var name = data[i].name;
  var source = data[i].source;
  var url = '/video/watch?id='+data[i].id;
  var image = data[i].image;
  console.log(name)
  return  <MovieCard poster ={image}
        url={url}
        title = {name}
        type = {source}  /> 
  
      
   
   
   })
}

 


    return (
      <>
  <Layout searchEngineHandler={this.searchEngineHandler}>
    
 
 <br/><br/><br/><br/>


     <div class="catalog" >

		<div class="container  ">
		 <div class="row">
 
<h1 className='text-light'>Recent Added</h1>
 {this.state.loading?<Spinner/> :renderData }
 
 
  
 
 
		 </div>
		</div>
	</div>
  </Layout>
     
      
      </>
    )
  }
}

export default HomePage