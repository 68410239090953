import React, { Component } from 'react'

// Watch
import { Routes, Route, Outlet, Link } from "react-router-dom";
import JwPlayer from './Component/Player/JwPlayer/JwPlayerNoDrm';
import JwPlayerDrmProtected from './Component/Player/JwPlayer/JwPlayerDrmProtected';
import HoichoiHomePage from './Container/Hoichoi/HomePage/HomePage';
import HoichoiSearchPage from './Container/Hoichoi/SearchPage/SearchPage';
import HoichoiWatchVideo from './Container/WatchVideo/WatchVideo';
import HoichoiRecentPlaying from './Container/Hoichoi/RecentPlaying/RecentPlaying';
import JwPlayerIframe from './Component/Player/JwPlayer/JwPlayerIframe';

import ReactGA from "react-ga4";
 const TRACKING_ID = "G-270X47BPPH"; 
 ReactGA.initialize(TRACKING_ID);
 ReactGA.send("pageview");
 

export class App extends Component {
  render() {
    return (
      <>
 <Routes>
 
 
 <Route  path="/" element={<HoichoiHomePage />}  />
 <Route  path="/search" element={<HoichoiSearchPage />}  />
 <Route  path="/play" element={<JwPlayerIframe />}  />
 <Route  path="/recentplaying" element={<HoichoiRecentPlaying />}  />
 <Route  path="/:typeWatch/watch" element={<HoichoiWatchVideo />}  />


 

 <Route exact path="/" element={<HoichoiHomePage />}  />
  
    </Routes>
             </>
    )
  }
}

export default App

 

 