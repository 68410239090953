import React, { Component } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { browserHistory } from 'react-router-dom';
import RedirectTo from '../../RedirectTo/RedirectTo';
import $ from 'jquery';
export class Header extends Component {
	state = {
		searchName :"",
		
	}
	_handleKeyDown = (e) => {

		var url = window.location.href;
		var url = new URL(url);
		var url =  url.href
		if (e.key === 'Enter') {  
			if(url.includes("search")){
				$('.header__search').toggleClass('header__search--active');
		this.props.searchEngineHandler(this.state.searchName);
	   }else{
	
	//   $('.header__search').toggleClass('header__search--active');
	// 		document.getElementById("searchButtonLink").click()
	  }
	}
	
	  }
	  searchHandler = (e)=>{
		var url = window.location.href;
		var url = new URL(url);
		var url =  url.href
	   if(url.includes("search")){
		$('.header__search').toggleClass('header__search--active');
		this.props.searchEngineHandler(this.state.searchName);
	   }
	 
	 
		
		
	  }
	
	searchButtonHandler=()=>{
		var url ='/search?s='+this.state.searchName;
// 		
// return <RedirectTo url={url}
//  />
	}
	searchInputHandler = (e)=>{
		var searchName = e.target.value;
		
		this.setState({
			searchName:searchName
		})
	}
	openSearchBarHandler = (e)=>{
				$(this).toggleClass('active');
		$('.header__search').toggleClass('header__search--active');

	 
			 
			 
 
		 
	}

	openMenuHandler = ()=>{
		
		//   $('.header__btn').toggleClass('header__btn--active');
		// $('.header__nav').toggleClass('header__nav--active');
		document.querySelector(".header__nav").classList.toggle('header__nav--active')
	}

	componentDidMount(){
 
   }

  render() {
    return (
      <>
      
      <header class="header">
		<div class="header__wrap">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="header__content">
							
							<Link to="/" class="header__logo">
								<img style={{"width":"30%"}} className="img-fluid" src="/assets/img/movies.png" alt=""/>
								
								 <span>Movie And Series</span>  
							</Link>
							

							
							<ul class="header__nav">
 					 
								<li class="header__nav-item">
									<a href="https://chorki.pages.dev" class="header__nav-link">Chorki</a>
								</li> 
								<li class="header__nav-item">
									<a href="https://hoichoi.pages.dev" class="header__nav-link">Hoichoi</a>
								</li> 
								<li class="header__nav-item">
									<a href="https://t.me/premiumshitt" class="header__nav-link">Join On telergram</a>
								</li> 
							 
							</ul>
							

							
							<div class="header__auth">
								<button class="header__search-btn" onClick={this.openSearchBarHandler} type="button">
									<i class="icon ion-ios-search"></i>
								</button>
 </div>
							

							
							<button onClick={this.openMenuHandler} class="header__btn" type="button">
								<span></span>
								<span></span>
								<span></span>
							</button>
							
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div  class="header__search">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="header__search-content">
							<input type="text" name="s" id='searchItemInput' onKeyDown={this._handleKeyDown} onChange={this.searchInputHandler} placeholder="Search for a movie, TV Series that you are looking for"/>
 {/* <button onClick={this.searchButtonHandler} type="button">search</button> */}
 <Link class="btn btn-primary text-light" id='searchButtonLink'  onClick={this.searchHandler} to={'/search?s='+this.state.searchName}>Search</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</header>
      
      </>
    )
  }
}

export default Header