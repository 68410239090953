import React, { Component } from 'react'
import './DashPlayer.css';

export class DashPlayer extends Component {
  render() {
    return (
      <>
         <div class="videoContainer" id="videoContainer">
        <video  preload="auto" autoplay="" src="">
        </video>
        <div id="videoController" class="video-controller unselectable">
          <div id="playPauseBtn" class="btn-play-pause" title="Play/Pause">
            <span id="iconPlayPause" class="icon-play"></span>
          </div>
          <span id="videoTime" class="time-display">00:00</span>
          <div id="fullscreenBtn" class="btn-fullscreen control-icon-layout" title="Fullscreen">
            <span class="icon-fullscreen-enter"></span>
          </div>
          <div id="bitrateListBtn" class="control-icon-layout" title="Bitrate List">
            <span class="icon-bitrate"></span>
          </div>
          <input type="range" id="volumebar" class="volumebar" value="1" min="0" max="1" step=".01"/>
          <div id="muteBtn" class="btn-mute control-icon-layout" title="Mute">
            <span id="iconMute" class="icon-mute-off"></span>
          </div>
          <div id="trackSwitchBtn" class="control-icon-layout hide" title="A/V Tracks">
            <span class="icon-tracks"></span>
          </div>
          <div id="captionBtn" class="btn-caption control-icon-layout hide" title="Closed Caption">
            <span class="icon-caption"></span>
          </div>
          <span id="videoDuration" class="duration-display">00:00:00</span>
          <div class="seekContainer">
            <div id="seekbar" class="seekbar seekbar-complete">
              <div id="seekbar-buffer" class="seekbar seekbar-buffer"></div>
              <div id="seekbar-play" class="seekbar seekbar-play"></div>
            </div>
          </div>
          <div id="bitrateMenu" class="menu hide unselectable menu-item-unselected" style={{left: "550px", top: "364px"}}>
            <div id="video">
              <div class="menu-sub-menu-title">Video</div>
              
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default DashPlayer