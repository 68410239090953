import React, { Component } from 'react'
import './Spinner.css';
export class Spinner extends Component {
  render() {
    return (
        <div class="lds-dual-ring d-flex justify-content-center"></div>
    )
  }
}

export default Spinner